import React, { Component } from 'react';
import ExitBanerImage from '../components/ExitBanerImage';
import ScrollBtn from '../components/ScrollButton';
import '../styles/FlowersPage.css';

import imgf1 from '../images/flowers/f1.webp';
import imgf2 from '../images/flowers/f2.webp';
import imgf3 from '../images/flowers/f3.webp';
import imgf4 from '../images/flowers/f4.webp';
import imgf5 from '../images/flowers/f5.webp';
import imgf6 from '../images/flowers/f6.webp';
import imgf7 from '../images/flowers/f7.webp';
import imgf8 from '../images/flowers/f8.webp';
import imgf9 from '../images/flowers/f9.webp';
import imgf10 from '../images/flowers/f10.webp';
import imgf11 from '../images/flowers/f11.webp';
import imgf12 from '../images/flowers/f12.webp';
import imgf13 from '../images/flowers/f13.webp';
import imgf14 from '../images/flowers/f14.webp';
import imgf15 from '../images/flowers/f15.webp';
import imgf16 from '../images/flowers/f16.webp';
import imgf17 from '../images/flowers/f17.webp';
import imgf18 from '../images/flowers/f18.webp';
import imgf19 from '../images/flowers/f19.webp';
import imgf20 from '../images/flowers/f20.webp';
import imgf21 from '../images/flowers/f21.webp';
import imgf22 from '../images/flowers/f22.webp';
import imgf23 from '../images/flowers/f23.webp';
import imgf24 from '../images/flowers/f24.webp';
import imgf25 from '../images/flowers/f25.webp';
import imgf26 from '../images/flowers/f26.webp';
import imgf27 from '../images/flowers/f27.webp';
import imgf28 from '../images/flowers/f28.webp';
import imgf29 from '../images/flowers/f29.webp';
import imgf30 from '../images/flowers/f30.webp';
import imgf31 from '../images/flowers/f31.webp';


class FlowersPage extends Component {

    state = {
        images: [imgf1, imgf2, imgf3, imgf4, imgf5, imgf6, imgf7, imgf8, imgf9, imgf10, imgf11, imgf12, imgf13, imgf14, imgf15, imgf16, imgf17, imgf18, imgf19, imgf20, imgf21, imgf22, imgf23, imgf24, imgf25, imgf26, imgf27, imgf28, imgf29, imgf30, imgf31],
        src: "",
        banerImage: false,
    }

    handleClick = (e) => {
        this.setState({
            src: e.target.src,
            banerImage: !this.state.banerImage,
        })
    }

    removeShowImg = () => {
        this.setState({
            banerImage: !this.state.banerImage,
        })
    }

    render() {

        let classesBaner = [' '];

        if (this.state.banerImage) {
            classesBaner.push('active');
        }
        if (!this.state.banerImage) {
            classesBaner.push('');
        }

        return (
            <>
                <section className='gallerysRoute'>
                    <div id="flowersSection">
                        <h1>galeria kwiatów</h1>
                        <ul>
                            {this.state.images.map((imgf, index) =>
                                <li key={index}>
                                    <img
                                        src={imgf}
                                        alt="flowers"
                                        onClick={this.handleClick}
                                    />
                                </li>
                            )}
                        </ul>
                        <div id="slideWindow" className={classesBaner.join('')}>
                            <img src={this.state.src} alt="" className="current" />
                            <ExitBanerImage exitImage={this.removeShowImg} />
                        </div>
                        <ScrollBtn />
                    </div>
                </section>
            </>
        );
    }

}

export default FlowersPage;
import React from 'react';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';
import '../styles/Navigation.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const list = [
    {
        name: "cottage",
        cdnFont: <span className="home"><FontAwesomeIcon icon="home" /></span>,
        path: "/garden",
        exact: true,
    },
    {
        name: "plant",
        cdnFont: <span className="seedling"><FontAwesomeIcon icon="seedling" /></span>,
        path: "/flowers",
    },
    {
        name: "apple",
        cdnFont: <span className="frog"><FontAwesomeIcon icon="apple-alt" /></span>,
        path: "/fruits",
    },
];


const handleClick = () => {
    const goToSection = $('main');
    const scrollBtn = $('#scrollBtn');
    goToSection.addClass('active')
    scrollBtn.addClass('active')
    $('body,html').animate({
        scrollTop: $(goToSection).offset().top
    }, 500)
}


const Navigation = () => {

    const menu = list.map(item => (
        <li key={item.name} >
            <NavLink to={item.path} exact={item.exact ? item.exact : false}
                onClick={handleClick}
            >
                {item.cdnFont}
            </NavLink>
        </li >
    ));

    return (
        <>
            <nav id="topmenu">
                <ul>
                    {menu}
                </ul>
            </nav>
        </>
    );
}

export default Navigation;

import React from 'react';
import '../styles/Footer.css';

const Footer = () => {
    return (
        <>
            <p>2019 &copy; Kazimierz Tadeusiak</p>
            <p>Wszelkie prawa zastrzeżone.</p>
        </>
    );
}

export default Footer;
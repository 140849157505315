import React, { Component } from 'react';
import '../styles/ButtonHeader.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ButtonHeader extends Component {
    state = {
        frog: false
    }

    handleClick = () => {
        this.setState({
            frog: !this.state.frog
        })
    }

    render() {
        let classess = [''];
        if (this.state.frog) classess.push('active');
        if (!this.state.frog) classess.push('');

        return (
            <>
                <button id="burgerBtn" className={classess.join(' ')} onClick={this.handleClick}>
                    <span className="frog"><FontAwesomeIcon icon="frog" /></span>
                </button>
            </>
        );
    }
}

export default ButtonHeader;
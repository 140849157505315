import React, { Component } from 'react';
import ExitBanerImage from '../components/ExitBanerImage';
import ScrollBtn from '../components/ScrollButton';
import '../styles/FruitsPage.css';

import imgs1 from '../images/fruits/s1.webp';
import imgs2 from '../images/fruits/s2.webp';
import imgs3 from '../images/fruits/s3.webp';
import imgs4 from '../images/fruits/s4.webp';
import imgs5 from '../images/fruits/s5.webp';
import imgs6 from '../images/fruits/s6.webp';
import imgs7 from '../images/fruits/s7.webp';
import imgs8 from '../images/fruits/s8.webp';
import imgs9 from '../images/fruits/s9.webp';
import imgs10 from '../images/fruits/s10.webp';


class FruitsPage extends Component {
    state = {
        images: [imgs1, imgs2, imgs3, imgs4, imgs5, imgs6, imgs7, imgs8, imgs9, imgs10],
        slide: false,
        src: "",
        btnImg: false
    }

    handleClick = (e) => {
        this.setState({
            slide: !this.state.slide,
            src: e.target.src
        })
    }

    removeShowImg = () => {
        this.setState({
            slide: !this.state.slide
        })
    }

    render() {

        let classesSlide = [''];

        if (this.state.slide) {
            classesSlide.push('active');
        };
        if (!this.state.slide) {
            classesSlide.push('');
        };

        return (
            <>
                <section className='gallerysRoute'>
                    <div id="fruitsSection">
                        <h1>galeria owocowo-warzywna</h1>
                        <ul>
                            {this.state.images.map((imgs, index) =>
                                <li key={index}>
                                    <img
                                        src={imgs}
                                        alt="fruits"
                                        onClick={this.handleClick}
                                    />
                                </li>
                            )}
                        </ul>
                        <div id="slideWindow" className={classesSlide.join('')}>
                            <img src={this.state.src} alt="" className="current" />
                            <ExitBanerImage exitImage={this.removeShowImg} />
                        </div>
                        <ScrollBtn />
                    </div>
                </section>
            </>
        );
    }

}

export default FruitsPage;
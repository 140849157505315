import React from 'react';
import { Route, Switch } from 'react-router-dom';
import GardenPage from '../pages/GardenPage';
import FlowersPage from '../pages/FlowersPage';
import FruitsPage from '../pages/FruitsPage';
import '../styles/GallerysRoute.css';


const GallerysRoute = () => {
    return (
        <>
            <Switch>
                <Route path="/" exact />
                <Route path="/garden" component={GardenPage} />
                <Route path="/flowers" component={FlowersPage} />
                <Route path="/fruits" component={FruitsPage} />
            </Switch>
        </>
    );
}

export default GallerysRoute;
import React from 'react';
import '../styles/ExitBanerImage.css';

const ExitBanerImage = (props) => {

    return (
        <>
            <svg onClick={props.exitImage} id="exit" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                <g id="cross">
                    <line className="crossExit" x1="5" x2="45" y1="5" y2="45" stroke="#2849a58e" strokeWidth="3" strokeLinecap="round" />
                    <line className="crossExit" x1="5" x2="45" y1="45" y2="5" stroke="#2849a58e" strokeWidth="3" strokeLinecap="round" />
                </g>
            </svg>
        </>
    );

}

export default ExitBanerImage;
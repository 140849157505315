import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './Header';
import GallerysRoute from './GallerysRoute';
import Footer from './Footer';
import '../styles/App.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas);

class App extends Component {
  render() {
    return (
      <Router>
        <>
          <header>
            {<Header />}
          </header>
          <main>
            {<GallerysRoute />}
          </main>
          <footer>
            {<Footer />}
          </footer>
        </>
      </Router >
    );
  }
}

export default App;

import React, { Component } from 'react';
import ExitBanerImage from '../components/ExitBanerImage';
import ScrollBtn from '../components/ScrollButton';
import '../styles/GardenPage.css';

import imgg1 from '../images/garden/g1.webp';
import imgg2 from '../images/garden/g2.webp';
import imgg3 from '../images/garden/g3.webp';
import imgg4 from '../images/garden/g4.webp';
import imgg5 from '../images/garden/g5.webp';
import imgg6 from '../images/garden/g6.webp';
import imgg7 from '../images/garden/g7.webp';
import imgg8 from '../images/garden/g8.webp';
import imgg9 from '../images/garden/g9.webp';
import imgg10 from '../images/garden/g10.webp';
import imgg11 from '../images/garden/g11.webp';
import imgg12 from '../images/garden/g12.webp';
import imgg13 from '../images/garden/g13.webp';
import imgg14 from '../images/garden/g14.webp';


class GardenPage extends Component {

    state = {
        images: [imgg1, imgg2, imgg3, imgg4, imgg5, imgg6, imgg7, imgg8, imgg9, imgg10, imgg11, imgg12, imgg13, imgg14],
        slide: false,
        src: "",
        btnImg: false
    }

    handleClick = (e) => {
        this.setState({
            slide: !this.state.slide,
            src: e.target.src
        })
    }

    removeShowImg = () => {
        this.setState({
            slide: !this.state.slide
        })
    }

    render() {
        let classesSlide = [''];

        if (this.state.slide) {
            classesSlide.push('active');
        };
        if (!this.state.slide) {
            classesSlide.push('');
        };

        return (
            <>
                <section className='gallerysRoute'>
                    <div id="gardenSection">
                        <h1>galeria ogrodu</h1>
                        <ul>
                            {this.state.images.map((imgg, index) =>
                                <li key={index}>
                                    <img
                                        src={imgg}
                                        alt="garden"
                                        onClick={this.handleClick}
                                    />
                                </li>
                            )}
                        </ul>
                        <div id="slideWindow" className={classesSlide.join('')}>
                            <img src={this.state.src} alt="" className="current" />
                            <ExitBanerImage exitImage={this.removeShowImg} />
                        </div>
                        <ScrollBtn />
                    </div>
                </section>
            </>
        );
    }

}

export default GardenPage;
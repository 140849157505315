import React from 'react';
import $ from 'jquery';
import '../styles/ScrollButton.css';

const handleClick = () => {
    const goTopSection = $('header');

    $('body,html').animate({
        scrollTop: $(goTopSection).offset().top
    }, 500)
}

const ScrollButton = () => {

    return (
        <>
            <svg id="scrollBtn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" onClick={handleClick}>
                <polyline id="scrollBtnStroke" points="10,40 25,10 40,40" stroke="#2849a58e" fill="transparent" strokeWidth="5" strokeLinecap="round"
                    strokeLinejoin="round" />
            </svg>
        </>
    );

}

export default ScrollButton;


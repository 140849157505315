import React from 'react';
import ButtonHeader from '../components/ButtonHeader';
import Navigation from '../components/Navigation';
import '../styles/Header.css';



const Header = () => {

    return (
        <>
            <div className="wrapper">
                <ButtonHeader />
                <Navigation />
                <h1>Witaj w ogrodzie</h1>
            </div>
        </>
    );
}

export default Header;

